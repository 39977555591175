import image1 from './assets/V3.png';
import image2 from './assets/IHOMP_LOGO.png';
import image3 from './assets/MMS_Logo_Top.png';


export const DATA = {
    shiro: {
        title: ''
    },
    about: {
        title: 'ABOUT'
    },
    experiences: {
        title: 'EXPERIENCES'
    },
    // projects: {
    //     title: 'PROJECTS'
    // },
    gallery: {
        title: 'GALLERY'
    }
}

export const TECH_STACK = {
    experience1: [
        {
            techname: 'Inventory Management',
            techdesc: ': Implemented features for real-time stock tracking, product categorization, and automated reorder alerts based on predefined thresholds.',
        },
        {
            techname: 'Database Integration',
            techdesc: ': Designed and integrated a relational database using MySQL for storing product data, including expiration dates, batch numbers, and supplier details.'
        },
        {
            techname: 'User Interface',
            techdesc: ': Developed an intuitive, user-friendly interface using React Typescript and Bootstrap.'
        },
        {
            techname: 'Reporting & Analytics',
            techdesc: ': Created detailed reporting features for inventory levels, sales trends, and expiry date tracking.'
        }
    ],
    experience2: [
        {
            techname: 'Patient Data Management',
            techdesc: ': Created secure modules to store and retrieve patient records, including demographic details, medical histories, prescriptions, and appointment schedules.'
        },
        {
            techname: 'Security & Privacy',
            techdesc: ': Implemented encryption and secure authentication (OAuth, JWT) to protect patient data and ensure compliance with healthcare regulations.'
        },
        {
            techname: 'Role-based access control (RBAC)',
            techdesc: ': encryption to protect sensitive data.'
        },
        {
            techname: 'Database Integration',
            techdesc: ': Designed a relational database using MySQL to store and query patient records efficiently.'
        },
    ],
    experience3: [
        {
            techname: 'Inventory Management',
            techdesc: ': Designed a system for tracking material stock levels, categorization, and consumption rates across multiple locations.'
        },
        {
            techname: 'Procurement Management',
            techdesc: ': Automated procurement processes by integrating supplier databases and managing purchase orders based on real-time stock levels.'
        },
        {
            techname: 'Material Usage Tracking',
            techdesc: ': Developed tools to track materials from receipt through to consumption, ensuring accurate records for cost analysis and auditing.'
        },
        {
            techname: 'Reporting & Analytics',
            techdesc: ': Developed custom reports for inventory forecasts, material usage trends, and supplier performance.'
        }
    ]
};

export const TECH_USED = {
    tech1: [
        {
            techused: 'React Typescript'
        },
        {
            techused: 'Laravel'
        },
        {
            techused: 'PHP'
        },
        {
            techused: 'HTML / CSS'
        },
        {
            techused: 'MySql'
        },
        {
            techused: 'API'
        },
        {
            techused: 'Jasper Reports'
        },
    ],
    tech2: [
        {
            techused: 'Laravel'
        },
        {
            techused: 'PHP'
        },
        {
            techused: 'MySql'
        },
        {
            techused: 'HTML / CSS'
        },
        {
            techused: 'OAuth'
        },
        {
            techused: 'API'
        },
        {
            techused: 'Jasper Reports'
        }
    ],
    tech3: [
        {
            techused: 'Adobe'
        },
        {
            techused: 'Photoshop'
        },
        {
            techused: 'InDesign'
        },
        {
            techused: 'Typography'
        }
    ]
}

export const SOCIALS = [
    {
        social: 'LinkedIn',
        link: 'https://www.linkedin.com/in/cristine-joy-san-juan/'
    },
    {
        social: 'GitHub',
        link: 'https://github.com/STUDYINGSHIRO'
    },
    {
        social: 'Facebook',
        link: 'https://www.facebook.com/tinnyswiftie/'
    },
]

export const GALLERY = [
    {
        img: image1,
        alt: 'logo1'
    },
    {
        img: image2,
        alt: 'developer'
    },
    {
        img: image3,
        alt: 'developer'
    }
]