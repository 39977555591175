import React from 'react'

export const About = () => {
    return (
        <>
            <p>
                I’m a developer who thrives at the dynamic intersection of design and development, driven by a deep passion for crafting user interfaces that are both accessible and pixel-perfect. My focus is on creating experiences that not only captivate users visually but are also meticulously engineered for optimal performance, functionality, and usability.
                <br /><br />
                I believe that great design is not just about aesthetics; it’s about how seamlessly it interacts with robust, thoughtful engineering to create intuitive and engaging digital solutions. By blending creativity with technical precision, I aim to deliver work that meets the highest standards of quality, ensuring every detail contributes to a meaningful user experience.
                <br /><br />
                Currently, I am a Lead Technical Developer at
                <span className='highlight'>
                    <strong> National Center for Mental Health</strong>
                </span>
                , where I lead a team of developers in building and maintaining web applications that support the organization’s mission to provide accessible mental health services.
            </p>
            <div className='about-container'>
                <span>
                    <p>Education</p>
                    <span className='bachelors'>Bachelor of Science in Computer Engineering</span>
                    <p>Major in Cybersecurity</p>
                    <p>Technological Institute of the Philippines - Manila</p>
                </span>
            </div>
        </>
    )
}
